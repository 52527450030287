#sidebar {
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    // padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    // overflow-x: hidden;
    // overflow-y: auto;

    background-image: url('../../content/images/2620726.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    max-width: 260px;

    a {
        color: white;
    }

    // .sidebar-toggle {
    //     display: none;
    // }

    .sidebar-heading {
        text-transform: uppercase;
        margin-bottom: 0.75rem !important;
        font-size: 1rem;
    }

    .sidebar-sticky {
        margin-left: -15px;
        margin-right: -15px;
    }

    .sidebar-sticky .nav-link {
        padding-left: 1.5rem;
    }

    .nav-link {
        font-size: small;
        // color: #797979;
    }

    .nav-link:hover {
        background: rgba(0,0,0,.1);
    }

    .nav-link svg {
        margin-top: -0.1rem;
        margin-right: 0.5rem;
        color:#0095f0;
        font-size: 1.25rem;
    }

    hr {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    //   visibility: hidden;
      transform:translateX(-100%);

    //   z-index: 1200;
      //width:80%; media query
    //   overflow-y: scroll;
    //   overflow-x: visible;
      transition: visibility .5s ease-in-out, transform .5s ease-in-out;
}

.brand-logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: -15px;
    margin-right: -15px;
    // background-color: white;
    height: 64px;
    padding: 5px 0;
}

#logo {
    height: 54px !important;
    max-width: 220px !important;
    background: no-repeat center center; 
    -webkit-background-size: contain;
    -moz-background-size: contain; 
    -o-background-size: contain; 
    background-size: contain; 
    margin: 0 auto !important;
}

#sidebar.bg-dark {
    // background-color: #212121 !important;
}

// .sidebar-sticky {
//     position: -webkit-sticky;
//     position: sticky;
    
//     position: relative;
//     top: 0;
//     height: calc(100vh - 48px);
//     padding-top: .5rem;
//     overflow-x: hidden;
//     overflow-y: auto;
// }


#sidebar.show{
    // visibility: visible;
    transform: translateX(0);
}

.screen-overlay {
    height: 100%;
    z-index: 1020;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0;
    visibility:hidden;
    background-color: rgba(0, 0, 0, 0.5);
    transition:opacity .5s linear, visibility .5s;
}
.screen-overlay.show {
    transition:opacity .5s ease;
    opacity:1;
    visibility:visible;
}

.sidebar-toggle {
    cursor: pointer;
    transition: all .5s ease-in-out;
    z-index: 1020;
    display: block;
    position: absolute;
    top: 0;
    left: 260px;
    color: black;
    font-size: x-large;
    height: 64px;
    width: 64px;
    
    .sidebar-toggle-inner {
        padding:13px;
        div {
            transition: all .5s ease-in-out;
            margin: auto;
            width: 35px;
            height: 5px;
            margin: 6px 0;
            background-color: rgba(0,0,0, 0.1);
          }
    }
}

@media only screen and (min-width: 992px) {
    .screen-overlay.show, .screen-overlay {
        display:none;
    }
}

@media only screen and (max-width: 992px) {
    .sidebar-toggle {
        height: 48px;
        width: 48px;

        .sidebar-toggle-inner {
            padding: 11px;
            div {
                width: 26.25px;
                height: 3px;
                margin: 4px 0;
              }
        }
    }
}

.sidebar-version {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 1rem !important;
}

.sidebar-version button {
    background-color: transparent;
    border-color: transparent;
    width: 80%;
    color: #444;
}

.changelog-markdown {
    padding: 3em;
    background-color: #F6F6F6;
    box-shadow: inset 0px 0px 5px 1px rgba(119, 119, 119, 0.25);
    -moz-box-shadow: inset 0px 0px 5px 1px rgba(119, 119, 119, 0.25);
    -webkit-box-shadow: inset 0px 0px 5px 1px rgba(119, 119, 119, 0.25);
    border-radius: 0.3rem;

    h1:after, h2:after {
        content: "";
        display: block;
        position: relative;
        top: .33em;
        border-bottom: 1px solid hsla(0,0%,50%,.20);
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 1.8em 0;
        line-height: 1.33;
    }
    :first-child {
        margin-top: 0;
    }
    h1 { 
        font-size: 2em;
    }
    h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
}