#booking-form-scanner form {
  width: 100%;
  max-width: 900px;
  margin: 0 auto !important;
  // margin: 50px auto;
  .spinner-border {
    margin-left: 0;
    position: relative;
    left: 0;
    width: 1rem;
    height: 1rem;
  }
  .scanner-wrapper {
    hr {
      position: absolute;
      z-index: 10000;
      border-color: red;
      border-width: 2px;
      margin-top: -1px;
      top: 50%;
      left: 15%;
      right: 15%;
    }
  }
}