.content-wrapper.container-fluid {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;

    .row {
        height: 100%;
    }
}

.modal-body {
    .well {
        min-height: 20px;
        padding: 0.25rem 1rem;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
    }
}

.height100 {
    height: 100%;
    max-height: 100%;
}

#cruise-calendar-view {

    .container,
    .container-fluid {
        padding-top: 30px;
    }

    .rbc-day-slot .rbc-event,
    #calendar-view .rbc-day-slot .rbc-background-event {
        // border-left: 1px solid white !important;
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        height: auto;
        box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.5);
    }

    // CALENDAR
    ///////////////////////////////////////////////

    .rbc-calendar {
        height: auto;
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 4.5rem;
        bottom: 1rem;

        // MONTH VIEW
        ///////////////////////////////////////////////

        div.rbc-month-view .rbc-month-row {

            // Current Date Number
            .rbc-now.rbc-current .rbc-button-link {
                font-weight: bold;
                color: #5d96c2;
            }

            // Full Height Events for Day Slots
            .rbc-row-content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;

                .rbc-addons-dnd-row-body {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 1.5em;
                    bottom: 0;

                    // "+{x} more" link bottom
                    .rbc-button-link.rbc-show-more {
                        position: absolute;
                        bottom: 0;
                    }
                }
            }

            // Day Slots Bg and hover
            .rbc-day-bg {
                transition: all 0.25s ease;
                z-index: 1000;
            }

            .rbc-day-bg.rbc-today {
                z-index: auto;
                // background-color: #f5f5f5;
                background-color: #d8edff;

                // box-shadow: inset 0px 0px 0px 4px #777777;
                // -moz-box-shadow: inset 0px 0px 0px 4px #777777;
                // -webkit-box-shadow: inset 0px 0px 0px 4px #777777;
            }

            .rbc-day-bg.rbc-off-range-bg {
                z-index: auto;
                // background-color: #f5f5f5;
            }

            .rbc-day-bg:hover {
                cursor: pointer;
                background: rgb(255, 255, 255);
                opacity: 0.25;
                background: rgba(225, 225, 225, 0.5);
                box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 1);
                -moz-box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 1);
                -webkit-box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 1);
            }

            // Hide Tags
            div.rbc-row-content div.rbc-addons-dnd-row-body div.rbc-event-content div.tags {
                display: none;
            }
        }

        // DAY VIEW
        ///////////////////////////////////////////////

        .rbc-time-view {

            .rbc-day-slot .rbc-event,
            #calendar-view .rbc-day-slot .rbc-background-event {
                border: none !important;
                border-bottom: 1px solid #DDDDDD !important;
            }

            div.rbc-time-content>div.rbc-time-gutter.rbc-time-column {
                margin-top: -560px;
                margin-bottom: -180px;

                div:nth-child(20),
                div:nth-child(21),
                div:nth-child(22),
                div:nth-child(23),
                div:nth-child(24) {
                    // background-color: #eee;
                }
            }

            div.rbc-time-content>div.rbc-day-slot.rbc-time-column {
                margin-top: -560px;
                margin-bottom: -180px;

                div:nth-child(20),
                div:nth-child(21),
                div:nth-child(22),
                div:nth-child(23),
                div:nth-child(24) {
                    background-color: #eee;

                    div {
                        border-top: none;
                    }
                }
            }

            .rbc-timeslot-group {
                min-height: 80px;
            }
        }

        .tags {
            position: absolute;
            bottom: 0;

            .badge {
                margin-right: 0.25rem;
                margin-bottom: 0.15rem;
            }
        }

        .rbc-day-slot {
            .rbc-event {
                // background: linear-gradient(135deg, #007bff 0%, #007bff 35%, #a5d0ff 50%, #007bff 65%, #007bff 100%);
                background-size: 400% 400%;
                height: 100vh;
            }
    
            // Hide day slot time
            .rbc-event-label {
                display: none;
            }
        }

        // AGENDA VIEW

        .rbc-agenda-view {
            .rbc-agenda-content {
                .rbc-agenda-table tbody tr {
                    transition: background-color 0.5s ease 0s; background-color: rgba(255, 255, 255, 0) !important;
                }
            }
            .rbc-agenda-event-cell:hover {
                cursor: pointer;
                background-color: #007BFF !important;
                color: white;
            }
        }

        .rbc-agenda-view .tags {
            position: relative;
            bottom: 0;
        }

        // OTHER

        .rbc-toolbar .rbc-toolbar-label {
            text-align: left;
            font-weight: 500;
            margin-left: 1rem;
        }
    }

    // STRETCH CALENDAR CARD
    .calendar-object .card {
        // padding-top: 10px;
        margin-top: -15px;
        margin-bottom: -30px !important;
        // margin: -15px;
    }

    // STRETCH FORM CARD
    .events-card-side-form {
        margin-right: -15px;
        margin-top: -15px;
    }

    // SIDE FORM FIXED
    .events-card-side-form {
        // position: fixed;
        // width: 31%;
        max-height: 100%;

        .card-body 
        { 
            overflow-y: scroll; 
        }        
    } 

    @keyframes updated-anim {
        0% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    .rbc-date-cell>a,
    .rbc-date-cell>a:active,
    .rbc-date-cell>a:visited {
        color: inherit;
        text-decoration: none;
        padding: 0.75em;
        position: absolute;
        top: -0.25em;
        right: 0;
        left: 0;
        bottom: 0;
        // background: rgba(225, 225, 225, 1);
    }

    .rbc-date-cell>a:hover {
        background: rgb(82, 41, 41);
    }

    .select-vessel {
        z-index: 1000;
        cursor: pointer;

        //padding: 5px 5px 5px 15px;
        //width: 100%;
        //.form-group {
        //    margin-bottom: 0;
        //    width: 100%;
        //}
        label {
            display: none;
        }

        //border-bottom: 1px solid #ccc;
    }

    .events-card-side-form .card-header span {
        float: right;
    }

    .input-group-datetimepicker {
        .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .MuiSvgIcon-root {
            font-size: 1rem;
            transform: scale(1.5);
        }
    }

    .calendar-loader {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        transition: all 0.5s ease;
        z-index: 1000;
        text-align: center;
        padding-top: 20%;
        pointer-events: none;
    }

    .calendar-loader.calendar-loading {
        pointer-events: all;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .spinner-border {
        width: 1rem !important;
        height: 1rem !important;
    }
}

@media (max-width: 576px) {

    #cruise-calendar-view .container,
    #cruise-calendar-view .container-fluid {
        padding: 0;

        .card.mb-4 {
            margin-bottom: 0 !important;
        }
    }

    #cruise-calendar-view .calendar-object {
        padding: 0;

        .card-body {
            padding: 0;

            .rbc-toolbar {
                margin-bottom: 0;
                padding: 0.25em 0.5em;

                .rbc-toolbar-label {
                    font-size: 12px;
                }
            }
        }

        .select-vessel {
            padding: 0.5em 0.5em;
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            .form-group {
                margin-bottom: 0;
                width: 100%;
            }

            label {
                display: none;
            }

            border-bottom: 1px solid #ccc;
        }
    }

}

@media (min-width: 576px) {
        // STRETCH CALENDAR CARD
        .calendar-object .card {
            margin: -15px;
        }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1600px) {
    .select-vessel.form-row {
        position: absolute;
        right: 270px;
        top: 14px;
        width: 400px;
    }
    #cruise-calendar-view .rbc-calendar {
        top: 1rem;
    }
}