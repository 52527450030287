#filter-form-view form {
    width: 100%;
    max-width: 900px;
    padding: 15px;
    // margin: 50px auto;
    .spinner-border {
        margin-left: -2rem;
        position: relative;
        left: 50%;
    }
}