#product-form-view form {
    width: 100%;
    max-width: 900px;
    padding: 15px;
    // margin: 50px auto;
    .spinner-border {
        margin-left: -2rem;
        position: relative;
        left: 50%;
    }
}

#product-view {
    #product-form-view form {
        margin: 0 auto !important;
    }
}
// #commission {
//     position: relative;
//     // display: none;
// }