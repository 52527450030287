#home-page {
    padding-top: 20px;

    .action-bar {

        position: absolute;
        left: -15px;
        margin-right: -30px;

        background-color: #eee;


        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        position: relative;
    }

    .btn-group-left {
        .btn:nth-child(2) {
            border-left: 1px solid #666;
            border-right: 1px solid #666;
            margin-left: 0px;
            margin-right: 1px;
        }
        .btn:nth-child(3) {
            border-right: 1px solid #666;
            margin-right: 1px;
        }
        .btn {
            min-width: 120px;
        }
    }

    .btn-group-center {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -225px !important;

        .btn:nth-child(2) {
            border-left: 1px solid #666;
            border-right: 1px solid #666;
            margin-left: 0px;
            margin-right: 1px;
        }

        .btn {
            min-width: 150px;
        }

        
    }

    .container, .container-fluid {
        padding-top: 1em;
    }

    .jumbotron {
        max-width: 1130px;
        height: 10vh;
        margin: 0 auto;

        //background-image: url('https://sporthelicopters.co.za/wp-content/uploads/2017/08/sub-header-3.jpg');
        //background-size: cover;
        //background-position: center center;
        display: flex;
        align-items: center;

        background-color: transparent;
        text-shadow: 0px 2px 2px #111111;

        .container {
            padding-top: 0;
        }
    
        h1 {
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 3vh;
            margin-bottom: 0;
            //padding-bottom: 150px;
        }
    }

    a {
        cursor: pointer;
        transition: all 0.5s ease;
    }

    a:hover {
        -webkit-box-shadow: 0px 1px 19px 0px rgba(0,102,255,1);
        -moz-box-shadow: 0px 1px 19px 0px rgba(0,102,255,1);
        box-shadow: 0px 1px 19px 0px rgba(0,102,255,1);
    }
    
    .card-img-top {
        height: 225px;
        //background-size: fit;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .card-disabled {
        opacity: 0.5;
        filter: grayscale(1);
        overflow: hidden;
    }

    .card-active {
        cursor: pointer;
    }

    .card-disabled::after {
        content: "COMING SOON";
        top:30%;
        color: white;
        position: absolute;
        font-weight: bold;
        width: 150%;
        margin-left: -25%;
        text-align: center;
        transform: rotate(-20deg);
        background: rgba(150,150,150,0.75);
        opacity: 0.9;
        font-size: 2em;
    }

    .card-coming-soon {
        position: relative;
    }
    
    .img1 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/book-a-flight.png");
    }
    .img2 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/board-a-flight.png");
    }
    .img3 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/group-bookings.png");
    }
    .img4 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/gift-voucher.png");
    }
    .img5 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/reports.png");
    }
    .img6 {
        background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2020/08/maintenenace.png");
    }

    .card-board-flight .card:hover,
    .card-booking-flight .card:hover,
    .card-reporting .card:hover,
    .card-users .card:hover,
    .card-board-yacht .card:hover,
    .card-booking-yacht .card:hover {
        background-size: 105%;
        .card-body {
            opacity: 1;
        }
    }

    .card-board-flight, .card-booking-flight, .card-reporting, .card-users, .card-board-yacht, .card-booking-yacht {
        .card {
            border: none;
            transition: all 0.5s ease;
            overflow: hidden;
            height: 23vh;
            max-height: 225px;
            background-position: center;
            background-size: 115%;
            background-repeat: no-repeat;
            background-color: rgb(153,153,153);
            .card-body {
                transition: all 0.5s ease;
                background: rgb(0, 0, 0);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.1) 100%);
                opacity: 0.8;
                .card-title {
                    color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    text-shadow: 0px 2px 2px #111111;
                }

                .card-title:hover {
                    text-underline: none;
                }
            }
        }
    }

    .card-board-flight {
        .card {
            background-image: url("./../../content/images/helicopters-boarding.jpg");
        }
    }

    .card-booking-flight {
        .card {
            background-image: url("./../../content/images/helicopter-flight.jpg");
        }
    }

    .card-board-yacht {
        .card {
            background-image: url("./../../content/images/yacht-boarding.jpg");
        }
    }

    .card-booking-yacht {
        .card {
            background-image: url("./../../content/images/yacht-booking.jpg");
        }
    }

    .card-reporting {
        .card {
            background-image: url("./../../content/images/reporting.jpg");
        }
    }

    .card-users {
        .card {
            background-image: url("./../../content/images/users.jpg");
        }
    }

    .card.shadow-sm {
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25) !important;
        -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25) !important;
        -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25) !important;
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }
}

@media only screen and (max-width: 992px) {
    #home-page {
        .card-board-flight, .card-booking-flight, .card-reporting, .card-users, .card-board-yacht, .card-booking-yacht {
            .card {
                background-size: cover;
            }
        }
        .card-board-flight .card:hover,
        .card-booking-flight .card:hover,
        .card-reporting .card:hover,
        .card-users .card:hover,
        .card-board-yacht .card:hover,
        .card-booking-yacht .card:hover {
            background-size: cover;
            transform: scale(1.025);
            .card-body {
                opacity: 1;
            }
        }
        .jumbotron {
            padding: 0rem 1rem;
        }
    }
}