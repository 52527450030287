@media print {
    h1.h4 {
        z-index: 10000;
        margin-bottom: -20px;
    }

    #titlebar,
    .btn-toolbar {
        display: none !important;
    }

    .content-wrapper.container-fluid {
        top: 0 !important;
    }

    @page {
        size: landscape !important;
    }

    html {
        transform: scale(0.90);
        transform-origin: top left;
        width: 111.11%;
    }

    #daily-report-print-view > div.card.flight-report-card > div > div > table {
        font-size: 1em;
    }
}

#daily-report-print-view {
    div.card.flight-report-card > div > div > table {
        font-size: 1em;
    }

    .daily-report-person {
        color: dodgerblue;
    }

    .bd-example::after {
        display: block;
        clear: both;
        content: "";
    }

    .btn {
        position: relative !important;
        min-width: 2.5rem;

        .MuiSvgIcon-root {
            position: absolute;
            width: 1em !important;
            left: 0;
            margin-left: 0.28em;
            height: 1em !important;
            top: 0.125rem;
        }
    }

    .bd-example {
        button {
            margin: 0 3px;
        }
    }

    .btn-group {
        // background:grey;
    }

    .table-wrapper {
        width: auto;
        margin-left: -1.3rem;
        margin-right: -1.3rem;
        margin-top: 2rem;

        .flight-report-status {
            font-weight: bold;
            text-align: left;
            width: 100%;
        }

        table.table.table-bordered > tfoot {
            background-color: #e0e0e0;
            box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        }

        table {
            width: 100%;
            table-layout: fixed; /* Ensure columns have fixed widths */
            
            th,
            td {
                // padding: 0.75rem;
                text-align: left;
                vertical-align: top;
                border-top: 1px solid #dee2e6;
                white-space: normal; 
                overflow: visible;
                text-overflow: clip;
                font-size: 0.9em; 
            }

            thead th {
                vertical-align: bottom;
                border-bottom: 2px solid #dee2e6;
            }

            tbody + tbody {
                border-top: 2px solid #dee2e6;
            }

            .table-bordered {
                border: 1px solid #dee2e6;

                th,
                td {
                    border: 1px solid #dee2e6;
                }
            }

            .table-striped tbody tr:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .table-hover tbody tr:hover {
                background-color: rgba(0, 0, 0, 0.075);
            }

            tbody tr td:nth-child(8),
            tbody tr td:nth-child(9),
            tbody tr td:nth-child(10),
            tbody tr td:nth-child(11),
            tbody tr td:nth-child(12) {
                background-color: #ccba7a; /* Highlighting the specified columns */
            }

            tbody tr td:nth-child(13) {
                background-color: #FFFFB2; /* Highlighting the specified column */
            }

            tfoot tr td {
                background-color: white; /* Ensure totals have a white background */
                font-weight: bold; /* Make totals bold */
            }

            /* Assign specific widths to each column */
            th:nth-child(1), td:nth-child(1) { width: 11.5%; } // SALES AGENT
            th:nth-child(2), td:nth-child(2) { width: 19.5%; } // ROUTE
            th:nth-child(3), td:nth-child(3) { width: 11.5%; } // PAX
            th:nth-child(4), td:nth-child(4) { width: 11.5%; } // FULL PRICE
            th:nth-child(5), td:nth-child(5) { width: 11.5%; } // DISCOUNT
            th:nth-child(6), td:nth-child(6) { width: 11.5%; } // SALES VALUE
            th:nth-child(7), td:nth-child(7) { width: 11.5%; } // AGENT COMM %
            th:nth-child(8), td:nth-child(8) { width: 11.5%; } // AGENT COMM COST
        }
    }

    .table-wrapper.group-craft .table {
        thead tr th:nth-child(2) { 
            background-color: #dcedc8;
        }
        tbody tr td:nth-child(2) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-pilot .table {
        thead tr th:nth-child(2) { 
            background-color: #dcedc8;
        }
        tbody tr td:nth-child(2) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-agent .table {
        thead tr th:nth-child(1) { 
            background-color: #dcedc8;
        }
        tbody tr td:nth-child(1) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-event .table {
        thead tr th:nth-child(2) { 
            background-color: #dcedc8;
        }
        tbody tr td:nth-child(2) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-sales-agent .table {
        thead tr th:nth-child(1) {
            background-color: #dcedc8;
        }
        tbody tr td:nth-child(1) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper tbody tr:hover {
        cursor: pointer;
    }

    div.card.flight-report-card {
        overflow: hidden;
        border: none;
        margin-bottom: 4rem;
    }
}

/* Handle text overflow on smaller screens */
@media (max-width: 767px) {
    table.table.table-bordered th,
    table.table.table-bordered td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8em; /* Adjusted text size for smaller screens */
    }
}