#titlebar {
    height: 64px;
    background-color: white;
    // background-color: #333;
    transition: all .5s ease-in-out;

    .brand-logo {
        // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: none !important;
        // margin-left: -15px;
        // margin-right: -15px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -27px;
        height: 64px;
        padding: 5px 0;
        width: 250px;
        z-index: 2000;

        #logo {
            height: 54px !important;
            max-width: 220px !important;
            background: no-repeat center center;
            background-size: contain;
            margin: 0 auto !important;

            border-bottom: none !important;
        }
    }

    ul.navbar-account {
        z-index: 2000 !important;
    }
    

    /* REFACTOR 2021 05 02 */
    .nav.nav-tabs {
        position: absolute;
        padding-left: 15px;
        border-bottom: 1px solid #ccc;

        bottom: 0;
        right: 0;
        left: 0;

        .nav-item {
            .nav-link {
                min-width: 100px;
                text-align: center;
                padding: 0.75rem 1rem;
                border: none;
            }
            .nav-link.active {
                background-color: #ccc;
            }
        }
    }
}

#titlebar.shadow {
    // box-shadow: 0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15) !important;
    box-shadow: 0 0.5rem 1rem #ccc !important;
}


@media only screen and (max-width: 992px) {
    #titlebar {
        height: 48px;
    }
}

