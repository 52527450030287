.dashboard-iframe {
    border: 0;
    margin-bottom: -15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    width: 100%;
    display: block;
    background-color: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

#report-page {
    background-color: #ddd;
    margin-left: -15px;
    margin-right: -15px;
}