@media print {
    h1.h4 {
        z-index: 10000;
        margin-bottom: -20px;
    }

    #titlebar,
    .btn-toolbar {
        display: none !important;
    }

    .content-wrapper.container-fluid {
        top: 0 !important;
    }

    @page {
        size: landscape !important;
    }

    html {
        transform: scale(0.90);
        transform-origin: top left;
        width: 111.11%;
    }

    #daily-report-print-view > div.card.flight-report-card > div > div > table {
        font-size: 1em;
    }
}

#daily-report-view-2 {
    div.card.flight-report-card > div > div > table {
        font-size: 1em; 
    }

    .daily-report-person {
        color: dodgerblue;
    }

    .bd-example::after {
        display: block;
        clear: both;
        content: "";
    }

    .btn {
        position: relative !important;
        min-width: 2.5rem;

        .MuiSvgIcon-root {
            position: absolute;
            width: 1em !important;
            left: 0;
            margin-left: 0.28em;
            height: 1em !important;
            top: 0.125rem;
        }
    }

    .bd-example {
        button {
            margin: 0 3px;
        }
    }

    .btn-group {
        // background:grey;
    }

    .table-wrapper {
        width: auto;
        margin-left: -1.3rem;
        margin-right: -1.3rem;
        margin-top: 2rem;

        .flight-report-status {
            font-weight: bold;
            text-align: left;
            width: 100%;
        }

        table.table.table-bordered > tfoot {
            background-color: #e0e0e0;
            box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        }

        table {
            width: 100%;
            table-layout: fixed; /* Ensure columns have fixed widths */
            
            th,
            td {
                // padding: 0.75rem;
                text-align: left;
                vertical-align: top;
                border-top: 1px solid #dee2e6;
                white-space: normal; /* Allow text to wrap */
                overflow: visible; /* Show full content */
                text-overflow: clip; /* Do not truncate text */
                font-size: 0.9em; /* Adjusted text size */
            }

            thead th {
                vertical-align: bottom;
                border-bottom: 2px solid #dee2e6;
            }

            tbody tr:nth-of-type(odd) {
                background-color: #F6F7F9;
            }

            tfoot {
                background-color: #e0e0e0;
                box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
            }

            tfoot tr td {
                background-color: white;
                font-weight: bold; 
            }
        }

        .visible-cell {
            border: 1px solid #dee2e6 !important;
            background-color: #fff;
        }

        th:nth-child(1), td:nth-child(1) { width: 4%; } // #
        th:nth-child(2), td:nth-child(2) { width: 5%; } // FLIGHT#
        th:nth-child(3), td:nth-child(3) { width: 5%; } // CRAFT
        th:nth-child(4), td:nth-child(4) { width: 4%; } // PILOT
        th:nth-child(5), td:nth-child(5) { width: 6%; } // AGENT
        th:nth-child(6), td:nth-child(6) { width: 3%; } // TIME
        th:nth-child(7), td:nth-child(7) { width: 4%; } // ROUTE
        th:nth-child(8), td:nth-child(8) { width: 3%; } // V#
        th:nth-child(9), td:nth-child(9) { width: 3%; } // PAX
        th:nth-child(10), td:nth-child(10) { width: 5%; } // FULL PRICE
        th:nth-child(11), td:nth-child(11) { width: 6%; } // DISCOUNT
        th:nth-child(12), td:nth-child(12) { width: 6%; } // CHARGED
        th:nth-child(13), td:nth-child(13) { width: 5%; } // CREDIT CARDS
        th:nth-child(14), td:nth-child(14) { width: 5%; } // CASH
        th:nth-child(15), td:nth-child(15) { width: 5%; } // EFT
        th:nth-child(16), td:nth-child(16) { width: 5%; } // PAYFAST
        th:nth-child(17), td:nth-child(17) { width: 5%; } // DEBT
        th:nth-child(18), td:nth-child(18) { width: 5%; } // TOTAL
        th:nth-child(19), td:nth-child(19) { width: 4%; } // NOTES
        th:nth-child(20), td:nth-child(20) { width: 5%; } // NAME & HOTEL
        th:nth-child(21), td:nth-child(21) { width: 2%; } // %

        .table {
            margin: 0;

            thead tr th:nth-child(13),
            thead tr th:nth-child(14),
            thead tr th:nth-child(15),
            thead tr th:nth-child(16),
            thead tr th:nth-child(17),
            tbody tr td:nth-child(13),
            tbody tr td:nth-child(14),
            tbody tr td:nth-child(15),
            tbody tr td:nth-child(16),
            tbody tr td:nth-child(17) {
                background-color: #ccba7a;
            }

            thead tr th:nth-child(18),
            tbody tr td:nth-child(18) {
                background-color: #FFFFB2;
            }
        }
    }

    .table-wrapper.group-craft .table {
        thead tr th:nth-child(3) {
            background-color: #dcedc8;
        }

        tbody tr td:nth-child(3) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-pilot .table {
        thead tr th:nth-child(4) {
            background-color: #dcedc8;
        }

        tbody tr td:nth-child(4) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-agent .table {
        thead tr th:nth-child(5) {
            background-color: #dcedc8;
        }

        tbody tr td:nth-child(5) {
            background-color: #dcedc8;
        }
    }

    .table-wrapper.group-event .table {
        thead tr th:nth-child(2) {
            background-color: #dcedc8;
        }

        tbody tr td:nth-child(2) {
            background-color: #dcedc8;
        }
    }

    .card.flight-report-card {
        overflow: hidden;
        border: none;
        margin-bottom: 4rem;
    }
}

/* Handle text overflow on smaller screens */
@media (max-width: 767px) {
    table.table.table-bordered th,
    table.table.table-bordered td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8em; /* Adjusted text size for smaller screens */
    }
}