.content-wrapper.container-fluid {
    width: auto;
    margin-left: 0px;
    transition: margin .5s ease-in-out;
}

.layout-private {

}

.app-environment {
    color: red;
    font-size: 100px;
    font-weight: bolder;
    position: fixed;
    bottom: 0;
    right: 20px;
    z-index: 999999999;
    pointer-events: none;
    opacity: 0.4;
    margin-top: 0;
}

.app-version {
    color: dimgray;
    pointer-events: none;
    opacity: 0.4;
    position: fixed;
    bottom: 0.5rem;
    right: 1rem;
    z-index: 10000;
}

.app-update {
    position: fixed;
    bottom: 0.25rem;
    left: 1rem;
    z-index: 10000;
    opacity: 0.9;
    pointer-events: none;
}

@media only screen and (min-width: 992px) {
    .content-wrapper.container-fluid.show-sidebar {
        margin-left: 260px;
    }
    #sidebar .sidebar-toggle {
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    #sidebar .sidebar-toggle {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #sidebar .sidebar-toggle {
        display: block;
    }
    #titlebar .navbar-top {
        display: none;
    }

    #calendar-view .calendar-object {
        display: none;
    }
}



// xs (for phones - screens less than 768px wide)
// sm (for tablets - screens equal to or greater than 768px wide)
// md (for small laptops - screens equal to or greater than 992px wide)
// lg (for laptops and desktops - screens equal to or greater than 1200px wide)