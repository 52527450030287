#login-page {
  height: 100%;
  width: 100%;
  display: table;

  background-image: url("https://sporthelicopters.co.za/wp-content/uploads/2018/04/sub-header_6.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  // background-size: fit;
  background-color: black;

  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  // animation: gradient 20s ease infinite;

  .col-form {
    height: 100%;
    background-color: #15202B;
    display: table;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5), 0 2px 10px 0 rgba(0, 0, 0, 0.12), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.05);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5), 0 2px 10px 0 rgba(0, 0, 0, 0.12), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.05);

    background-image: url('./2620726.4c2b651d.jpg');
    background-size: cover;
  }

  .row {
    height: 100%;
  }

  .container {
    height: 100vh;


  }

  h1 {
    color: white;
    font-weight: bold;
  }

  input {
    background: none;
    // border: #3D5466 1px solid;
    border: rgba(255, 255, 255, 0.1) 1px solid;
    color: white;
  }

  input:focus {
    box-shadow: 0 0 0 0.05rem #1DA1F2;
    border: #1DA1F2 1px solid;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    border-color: #dc3545;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: 0 0 0 0.05rem #dc3545 !important;
  }

  .btn-primary {
    transition: all 0.5s ease;
  }

  .btn-primary:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
  }

  // Define vars we'll be using
  $sin45: 0.70710678;

  $brand-success: #5cb85c;
  $brand-failure: #FF0000;
  $loader-size: 8em;
  $symbol-thickness: 2px; // Check thickness
  $check-height: $loader-size/2;
  $check-width: $check-height/2;
  $check-left: (
    $loader-size/7 + $loader-size/12
  ); // 7/12 is a nice centrepoint of the tick
$check-color: $brand-success;
$cross-color: $brand-failure;
$cross-offset: (
  $check-height / $sin45) - $check-height;

.circle-loader {
  border: $symbol-thickness solid rgba(0, 0, 0, 0.2
  );
border-left-color: $check-color;
animation-name: loader-spin;
animation-duration: 1s;
animation-iteration-count: infinite;
animation-timing-function: linear;
position: relative;
display: inline-block;
vertical-align: top;

&,
&:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}
}

.circle-loader-wrapper {
  margin: 30px 0 0 10px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.circle-loader-wrapper.loader-visible {
  height: 8em;
  opacity: 1;
}

.success {
  -webkit-animation: none;
  animation: none;
  border-color: $brand-success;
  transition: all 500ms ease-out;

  .status {

    &.draw:after {
      animation-duration: 1.2s;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $symbol-thickness solid $check-color;
      border-top: $symbol-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }
}

.failed {
  -webkit-animation: none;
  animation: none;
  border-color: $brand-failure;
  transition: all 500ms ease-out;

  .status {
    top: 50%;
    left: 50%;
    position: absolute;

    &.draw {

      &:before,
      &:after {
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-name: crossmark;
      }

      &:before {
        transform: scaleX(-1) rotate(45deg);
      }

      &:after {
        transform: scaleX(-1) rotate(225deg);
      }
    }

    &:before,
    &:after {
      opacity: 1;
      height: $check-height / 2;
      width: $check-height / 2;
      transform-origin: left top;
      border-right: $symbol-thickness solid $cross-color;
      border-top: $symbol-thickness solid $cross-color;
      content: '';
      position: absolute;
    }

    &:before {
      left: calc(#{$cross-offset} - #{($symbol-thickness*2)});
      top: calc(#{$symbol-thickness * 2} - #{$cross-offset});
    }

    &:after {
      left: calc(#{($symbol-thickness * 2)/$sin45} - #{$cross-offset});
      top: calc(#{$cross-offset} - #{($symbol-thickness)/$sin45});
    }
  }
}


@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }

  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }

  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes crossmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }

  40% {
    height: $check-width;
    width: $check-width;
    opacity: 1;
  }

  100% {
    height: $check-width;
    width: $check-width;
    opacity: 1;
  }
}
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.centerrr {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 2% auto 0 auto;

  // -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 30px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin #inputUsername {
  margin-bottom: 10px;
  // margin-bottom: -1px;
  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
}

#inputUsername {
  // background-image: url('&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;'); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;
}

#inputPassword {
  // background-image: url('&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;'); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;
}