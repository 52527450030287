.bd-example::after {
    display: block;
    clear: both;
    content: "";
}
.btn {
    position: relative !important;
    min-width: 2.5rem;
    .MuiSvgIcon-root {
        position: absolute;
        width: 1em !important;
        left: 0;
        margin-left: 0.28em;
        height: 1em !important;
        top: 0.125rem;
    }
}


.bd-example {
    button {
        margin: 0 3px;
        margin-bottom: 0.2rem;
    }
    #global_search {
        display: inline-block;
        width: 13rem;
        //position: absolute;
        //margin-top: -0.24rem;
        right: 6rem;
    }
}

.btn-group {
    // background:grey;
}