.passenger-manifest-form {
  .spinner-border {
    margin-left: -1rem;
    position: relative;
    left: 50%;
  }
  .datagrid {

  }
  .passenger-manifest-totals {
    margin-bottom: 2rem;
    text-align: center;
  }
}