.nav.nav-tabs {
    // position: absolute;
    // padding-left: 15px;
    border-bottom: 1px solid #bbb !important;

    // bottom: 0;
    // right: 0;
    // left: 0;

    .nav-item {
        .nav-link {
            color: #0056b3;
            // min-width: 100px;
            // text-align: center;
            // padding: 0.75rem 1rem;
            // border: none;
            .MuiSvgIcon-root {
                margin-right: 5px;
                margin-top: -2px;
            }
            text-decoration: none;
        }
        .nav-link.active {
            color: #495057;
            border-top: 1px solid #bbb !important;
            background: #ccc;
            text-decoration: none;
        }
        .nav-link:active {
            border-top: 1px solid #aaa !important;
            background: rgb(170,170,170);
            background: linear-gradient(180deg, rgba(170,170,170,1) 0%, rgba(204,204,204,1) 100%); 
            text-decoration: none;
        }
        .nav-link:hover {
            color: #007bff;
            text-decoration: underline;
            background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgba(204,204,204,1) 100%);
            border-top: 1px solid #ddd !important;
            text-decoration: none;
        }
    }
}